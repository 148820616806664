p {
  margin: 0;
}

.countryCode {
  color: #4d4d4d;
  font-size: 1rem !important;
  font-weight: 400;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

input[type="text"],
input[type="number"] {
  font-size: 1rem;
}

/* Theme Values */
:root {
  --color-primary-green: #67833e;
  --color-primary-info: #3980ff;
  --color-primary-alert: #fb6a6a;

  --color-bg: #e5e5e5;

  --color-grey-1: #e3e3e3;
  --color-grey-2: #aaaaaa;
  --color-grey-3: #ababab;
  --color-grey-4: #b8b8b8;
  --color-grey-5: #939393;
  --color-grey-6: #8d8d8d;
  --color-grey-7: #868686;
  --color-grey-8: #808080;
  --color-grey-9: #797979;
  --color-grey-10: #6a6a6a;
  --color-grey-11: #656565;
  --color-grey-12: #5b5b5b;
  --color-grey-13: #525252;
  --color-grey-14: #3c3c3c;

  --font-size-8: 0.5rem;
  --font-size-9: 0.5625rem;
  --font-size-10: 0.625rem;
  --font-size-11: 0.6875rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-15: 0.9375rem;
  --font-size-16: 1rem;

  --font-family-poppins: Poppins;
}


.psuduoRoute {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  align-self: center;
  justify-content: center;
  /* height: 90vw; */
  /* border: groove black; */
}

@media screen and (min-device-width: 500px) {
  .psuduoRoute {
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-self: center;
    overflow-y: scroll;
    /* height: 90vw; */
    /* border: groove black; */
  }
}


@media screen and (max-device-width: 500px) {
  #innerContainer {
    /* background-color: black; */
    /* height: 100%; */
    width: 100%;
    background-color: #fff;
    overflow: scroll;
    /* overflow-y: scroll; */
    /* height: 90vw; */
    /* border: groove black; */
    position: relative;
  }
 
  ::-webkit-scrollbar {
    display: none !important ;
  }
}

@media screen and (min-device-width: 500px) {
  #innerContainer {
    /* background-color: black; */
    /* height: 100%; */
    width: 400px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: scroll;
    /* height: 100%; */
    /* border: groove black; */
    position: relative;
  }
  ::-webkit-scrollbar {
    width: 8px !important;
  }
}

.desktop{
  background-color: #fff !important;
  height: 100% !important;
  width: 100% !important;
}

#call-waiter-btn {
  font-family: Poppins;
  color: white;
  background-color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  pointer-events: all;
  z-index: 16;
  margin-right: 4rem;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* width: 8px !important; */
}

::-webkit-scrollbar-thumb {
  background-color: #b3b2b2;
  border-radius: 5px;
  /* width: 8px !important; */
}

 


@media screen and (max-device-width: 500px) {
  ::-webkit-scrollbar {
    display: none !important; 
    /* width: 10px !important; */
  } 
}