.price {
  font-weight: 400 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.money-symbol {
  font-size: 0.9375rem !important;
  /* position: relative; */
  font-weight: 300 !important;
  margin-right: 0.6px;
  display: inline;
  margin-right: 2px;
  font-family: none;
  /* line-height: 9.5px; */
  top: 0px;
  /* margin-left: 10px; */
}

.offer-money-symbol{
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  margin-right: 0.6px;
  display: inline;
  margin-right: 2px;
  font-family: none;
  top: 0px;
}

.price-container {
  /* width: 40px; */
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
  justify-content: center;
}

.offer-price-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #67833e;
  text-decoration: line-through;
}

.price span {
  top: 0px;
  /* line-height: 10px; */
  font-size: 0.9375rem;
}

.big span {
  font-size: 1.125rem;
}


.money-sup {
  font-family: Poppins;
  font-size: 0.7rem !important;
  position: relative;
  top: 0px;
  font-weight: 300 !important;
  top: -2px;
}
